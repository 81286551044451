const compare = (a, op, b) => {
  switch (op) {
    case 'eq':
      return `${a}` === `${b}`
    case 'ne':
      return `${a}` !== `${b}`
    case 'lt':
      return a < b
    case 'gt':
      return a > b
    default:
      throw new Error('invalid operator')
  }
}

export default compare
