import gql from "graphql-tag"
import config from '~/config'

export default gql`
  query participant ($id: MongoID!) {
    participant(_id: $id){
      _id
      eventId
      userId
      remarks
      profile {
        _id
        prefix
        postalCode
        imageUrl
        nationalId
        gender
        nationality
        firstName
        lastName
        email
        weight
        height
        telephone
        club
        birthDate
        healthIssues
        allergies
        bloodType
        consent
        imageValidation {
          valid
          errors
        }
        emergencyContact {
          name
          relationship
          phone
        }
        street
        locality
        city
        country
        province
        taxInvoice {
          type
          taxId
          address
          name
          _id
        }
        receipt {
          type
          taxId
          address
          name
          _id
        }
        address {
          region
          locality
          country
          postalCode
        }
      }
      eventId
      registrationId
      order
      createdByUserId
      event {
        _id
        isLineLogin
        ticketGroups {
          _id
          backgroundColor
          order
          hidden
          name {
            en
            th
            zh
          }
        }
        ticketQuestions {
          _id
          kind
          masterKind
          inputType
          order
          name {
            en
            th
          }
          opts
          items {
            _id
            imageUrl
            value {
              en
              th
            }
          }
        }
        ticketTypes {
          _id
          group
          groupId
          price
          shirtEnabled
          finisherShirtEnabled
          shirtTypeIds
          shirtIds
          finisherShirtTypeIds
          finisherShirtIds
          name {
            en
            th
          }
          participantFields {
            _id
            name
            enabled
            required
          }
          race {
            _id
            name {
              en
              th
            }
            distance
          }
          questions {
            _id
            kind
            masterKind
            inputType
            shirtTypeIds
            required
            order
            name {
              en
              th
            }
            condition {
              _id
              questionId
              operator
              value
            }
            opts
            items {
              _id
              imageUrl
              value {
                en
                th
              }
            }
          }
        }
        settings {
          _id
          ticket {
            _id
            numberDigits
          }
          participant {
            _id
            uniqueNationalId
            uniqueEmail
            fields {
              _id
              name
              enabled
              required
              extraMessage {
                ${config.locale_list}
              }
              validations {
                _id
                type
                params
                message {
                  ${config.locale_list}
                }
              }
            }
          }
          virtualRun {
            _id
            fields {
              _id
              enabled
              required
            }
            period {
              start
              end
              _id
            }
            isAutoApproved
            isOCREnabled
            isTime
            raceTeam
            distanceMode
            donationPerDistance
            maxDonation
            baseDonation
            noEcer
            ecerV2
            isMultiRace
            finisherwall
            splitRaces
            distanceLimit
            virtualRunMessage
            chartsMode
            limitSubmit
            _id
          }
          payment {
            currency
          }
        }
        shirts {
          _id
          shirtTypeId
          name {
            en
            th
          }
        }
        finisherShirts {
          _id
          shirtTypeId
          name {
            en
            th
          }
        }
        shirtCounts {
          _id
          shirtTypeId
          quantity
          count
          name {
            en
            th
          }
        }
        shirtTypes {
          _id
          imageUrl
          name {
            en
            th
          }
        }
        finisherShirtTypes {
          _id
          imageUrl
          name {
            en
            th
          }
        }
      }
      registration {
        _id
        status
        registrationType {
          _id
          ticketTypeIds
        }
      }
      tickets {
        _id
        externalCouponCode
        eBib {
          profile {
            url
            top
            left
            width
            height
            zoom
          }
        }
        eCer {
          profile {
            url
            top
            left
            width
            height
            zoom
          }
        }
        reservedNumber
        number
        eventCode
        productTypeId
        eventId
        registrationId
        participantId
        nameOnBib
        couponCodeText
        couponCodeId
        shirtTypeId
        shirtId
        finisherShirtTypeId
        finisherShirtId
        kind
        remarks
        delivery
        trackingCode
        deliveryCarrier
        deliverTo {
          recipient
          street
          city
          country
          locality
          region
          postalCode
        }
        cancelRequest {
          _id
          status
          method {
            _id
            name {
              en
              th
              zh
            }
          }
          response {
            _id
          }
        }
        ticketType {
          _id
          group
          groupId
          externalCoupon {
            type
          }
          ticketGroup {
            _id
            backgroundColor
            name {
              en
              zh
              th
            }
            hidden
          }
          price
          deliveryOption
          deliveryPrice
          shirtEnabled
          finisherShirtEnabled
          quantity
          name {
            en
            th
            zh
          }
          participantFields {
            _id
            name
            enabled
            required
            extraMessage {
              ${config.locale_list}
            }
            validations {
              type
              params
              message {
                ${config.locale_list}
              }
            }
          }
          eBibEnabled
          eBib {
            _id
            name
            eventId
            nameOnBib
            bibNumber
            fonts {
              fontFamily
              fontSrcUrl
            }
            nameField
            backgroundUrl
            profileEnabled
            profilePosition
            profile {
              url
              top
              left
              width
              height
              zoom
            }
            numberStyle {
              font
              textAlign
              fillStyle
              top
              left
              fontSize
            }
            nameStyle {
              font
              textAlign
              fillStyle
              top
              left
              fontSize
            }
          }
          virtualBib {
            _id
            backgroundUrl
            nameField
            fonts {
              _id
              fontFamily
              fontSrcUrl
            }
            numberStyle {
              _id
              font
              textAlign
              fillStyle
              top
              left
            }
            nameStyle {
              _id
              font
              textAlign
              fillStyle
              top
              left
            }
          }
          race {
            _id
            name {
              en
              th
              zh
            }
            eCer {
              _id
              name
              eventId
              nameOnBib
              bibNumber
              totalDistance
              totalDistanceUnit
              submittedCount
              totalDistanceEnabled
              submittedCountEnabled
              totalDistanceStyle {
                font
                textAlign
                fillStyle
                top
                left
                fontSize
              }
              submittedCountStyle {
                font
                textAlign
                fillStyle
                top
                left
                fontSize
              }
              fonts {
                fontFamily
                fontSrcUrl
              }
              nameField
              backgroundUrl
              profileEnabled
              profilePosition
              numberStyle {
                font
                textAlign
                fillStyle
                top
                left
                fontSize
              }
              nameStyle {
                font
                textAlign
                fillStyle
                top
                left
                fontSize
              }
              profile {
                url
                top
                left
                width
                height
                zoom
              }
            }
            distance
            distanceUnit
            startTime
            ageCategoryDiff
            ageRangeStrict {
              male {
                min
                max
              }
              female {
                min
                max
              }
              lgbtq {
                min
                max
              }
            }
            ageCategories {
              _id
              min
              max
              gender
            }
          }
          questions {
            _id
            kind
            masterKind
            inputType
            default
            shirtTypeIds
            order
            required
            name {
              en
              th
              zh
            }
            opts
            items {
              _id
              imageUrl
              value {
                en
                th
                zh
              }
            }
          }
        }
        options (sort: ORDER_ASC) {
          _id
          value
          order
          itemId
          itemIds
          shirtId
          shirtTypeId
          questionId
          question {
            _id
            kind
            inputType
            shirtTypeIds
            order
            name {
              en
              th
              zh
            }
            condition {
              _id
              questionId
              operator
              value
            }
            items {
              _id
              imageUrl
              value {
                en
                th
                zh
              }
            }
          }
        }
      }
    }
  }
`