import React from 'react'
import {
    Card, 
    Table,
    Row,
    Col
} from 'antd'

import RApaxChart from "react-apexcharts";
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'

// const objCount = (obj) => {
//     let counter = 0;
//     if(typeof obj === 'object')for (const rowdata of Object.keys(obj)) {
//       rowdata
//       counter++
//     }
//     return counter
// }

const objCount = (obj) => {
    let counter = 0;
    if(typeof obj === 'object')for (const rowdata of Object.keys(obj)) {
      rowdata
      counter++
    }
    return counter
}

const GraphApex = ({param}) => {

    var shirtTypesMap = []

    const datasource = param.datasource 
    const chartRes = param.chart  
    const shirtsIndex  = param.shirtsIndex        

    var finisherShirtTypesMap = param.typemap 
    const dataColors = ['#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',
//------------------------------------------------------------------------------
                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066'
                    ] 

    const val = {"row":[], "label":[], "value":[], 
                  "chart":{
                      "bar":{
                          "bgcolor":[]
                      }, 
                  },
                  "rows":0,
                  "total":0,
                  "max":0  
                }

    finisherShirtTypesMap.forEach(shirtType => {
        shirtTypesMap[shirtType._id] = shirtType.name.th
    }) 

    const indexOfShirts = {"shirtTypeId":{data:[], rows:0},
                            "shirtId":{data:[], rows:0}
                            }    
    for(const [keyi, valuei] of Object.entries(_groupBy(shirtsIndex, 'shirtTypeId'))){
        const _name = shirtTypesMap[keyi]
        indexOfShirts.shirtTypeId.data[keyi] = {order:indexOfShirts.shirtTypeId.rows++, _id:keyi, name:_name}
    }                               
    for(const [keyi, valuei] of Object.entries(_groupBy(shirtsIndex, '_id'))){
        indexOfShirts.shirtId.data[keyi] = {order:indexOfShirts.shirtId.rows++, name:valuei[0].name.en}
    }   

    const dsRebuild = {groupShirtType:[]}
    const dsRebuildx = []
    if(datasource){

        //console.log('-finisher-datasource', datasource)
        const byShirtTypes   = _groupBy(datasource, 'shirtTypeId')

        let byShirtType = []
        for(const [keyTypeId, TypeItem] of Object.entries(byShirtTypes)){
            if( keyTypeId !== null && TypeItem){        
                for(const [keyj, valuej] of Object.entries(TypeItem))if(valuej.shirtTypeId !== undefined){

                    if(byShirtType[valuej.shirtTypeId] === null || byShirtType[valuej.shirtTypeId] === undefined){
                        byShirtType[valuej.shirtTypeId] = {typeId: valuej.shirtTypeId,  
                                                            name: shirtTypesMap[valuej.shirtTypeId] || "UNKNOW", 
                                                            data:[]}
                    }
                    
                    let objData = {shirtTypeId: valuej.shirtTypeId, 
                                    order: indexOfShirts.shirtId.data[valuej._id].order, 
                                    size: {_id:valuej._id, name:valuej.name}, 
                                    count: valuej.count, 
                                    quantity: valuej.quantity,
                                    __typename: "ShirtCount"}                            

                    if(byShirtType[valuej.shirtTypeId].data[valuej._id] === null || byShirtType[valuej.shirtTypeId].data[valuej._id] === undefined){}else{
                        objData = byShirtType[valuej.shirtTypeId].data[valuej._id]
                        objData.count += valuej.count

                        if(valuej.quantity != null)
                            objData.quantity += valuej.quantity
                    }
                
                    byShirtType[valuej.shirtTypeId].data[valuej._id] = objData;              
                
                }
            }
        }        
    
        let arrIndex = 0
        for(const [key, value] of Object.entries(byShirtType)){
            let idata = {}
            let iDataIndex = 0
            for(const [keyi, valuei] of Object.entries(value.data)){
                valuei.key = keyi
                idata[iDataIndex++]  = valuei
            }

            value.key = key
            value.data = idata
            dsRebuild.groupShirtType[arrIndex++] = value            
        }     
          
        if(objCount(dsRebuild.groupShirtType)){
            dsRebuild.groupShirtType = _orderBy(dsRebuild.groupShirtType, 'name', 'asc')      

            if(objCount(dsRebuild.groupShirtType[0].data))for(const [keyi, valuei] of Object.entries(dsRebuild.groupShirtType[0].data)){

                val.value[keyi] = 0
                val.label[keyi] = valuei.size.name.th || ''
                val.chart.bar.bgcolor[keyi] = '#efefef'
                val.max = 0  
                val.rows++

                if(val.max < valuei.value){
                    val.max = valuei.value    
                }

                val.value[keyi] = valuei.count === null ? 0 : valuei.count
                val.total += val.value[keyi]
            }
        }
          
    }

    let finisherShirts = null
    if( objCount(dsRebuild.groupShirtType) ){
        finisherShirts = _orderBy(dsRebuild.groupShirtType[0].data, 'order', 'asc').map((record, index)=>{ 
        record.color = dataColors[index] 
        return record
        }) 
    }   

    console.log('-finisher-shirtsIndex', finisherShirts)

    const valueList = val    
 
    const apexChart = {
              options: {      
                chart: {
                    width: '100%',
                    type: 'donut', 
                    toolbar: {
                        show: false,
                    },                                         
                },                
                labels: valueList.label,           
                colors: dataColors,          
                legend: {
                    show: false,
                    position: 'bottom'
                },     
                dataLabels: {
                    enabled: false
                },    
                plotOptions: {
                    pie: {
                        customScale: 0.8,
                        hover: {
                            filter: {
                                type: 'none',
                            },
                        },     
                        donut: {
                            size: '65%',                      
                            labels: {
                                show: true,
                                total: {
                                  showAlways: true,
                                  show: true,
                                  label: valueList.total,
                                  fontSize: '3pc',  
                                  fontWeight: 'bold',

                                  formatter: () => {
                                      return 'ตัว'   
                                  }  
                                                                                               
                                },                          
                            }                                
                        }, 
                        roseType : true                                                                
                    }, 
                },   
                stroke:{
                    width: 5
                }                      
            },          
            series: valueList.value, 
    }    

    const finisherShirtColumns = [
        {
          title: 'NAME',
          dataIndex: 'size.name.en',
          key: `ID`,
          render: (record, row) => {
            var _style = {borderColor:'#EFEFEF'}
            if(row.count>0){ 
              _style = {borderColor:row.color}
            }

            return <div class="div-inline div-top" >
              <div><div class="pin-circle" style={_style} >&nbsp;</div></div><div>{record}</div>
            </div>             
          }         
        },
        {
            title: 'COUNT',
            dataIndex: 'count',
            key: `finisherShirtTypeCount`,
            className: "amount center small",
            render: (value) => { 
              return value === null ? <span style={{color:'#EFEFEF'}}>0</span> : value
            }               
        },
        {
          title: 'STOCK',
          dataIndex: 'quantity',
          key: `quantity`,
          className: "amount center small",
          render: (value) => { 
            return value === null ? <span>&infin;</span> : value//(record.count) ? 0 : 1
          }          
        }
      ]   
 
    return (
        <div>
            <Card
                hoverable
                cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        </div>}
                style={{marginBottom: '20px'}}
            >
              <Row gutter={16} >
                    {valueList.rows>0 && 
                    <Col sm={24} md={24} lg={9} >  
                        <RApaxChart
                            options={apexChart.options}
                            series={apexChart.series}
                            type={apexChart.options.chart.type}
                            width="100%"
                            height="325"
                        />
                    </Col>                    
                    }       
                    {valueList.rows>0 ? 
                    <Col sm={24} md={24} lg={15} >              
                      <Table
                          style={{whiteSpace: 'nowrap'}}
                          scroll={{ x: true }}
                          className="eventFgma"
                          columns={finisherShirtColumns}
                          dataSource={finisherShirts}
                          loading={param.loading}
                          locale={{emptyText: <div style={{fontSize:"10px"}}>NO DATA</div>}}
                          rowKey={record => record._id}
                          pagination={false}
                        /> 
                    </Col> : <div class="eventFgma-gray small" style={{textAlign:'center'}}>NO DATA</div>}
                </Row>
            </Card>
        </div>
    )


}

export default GraphApex
