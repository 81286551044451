import React from 'react'
import { Form, Input, InputNumber, Switch, Modal, Button } from 'antd'
import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import mongoid from 'mongoid-js'
import Uploader from '~/components/Uploader'
import defaults from '~/data/defaults.json'
import initialValues from '~/data/initialValues.json'
import withRepeaterHandler from '~/utils/withRepeaterHandler'
import Error404 from '~/components/Error/404'
import { RepeaterItem } from '~/styles'
import config from '~/config'

import { withTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
}
const newShirtTypeId = mongoid()
@Form.create()
@withRepeaterHandler
class ShirtTypeForm extends React.Component {
  state = {
    itemsDeletedIndexes: [],
    newShirtTypeId: false
  }
  confirmDelete = ({text, handleDelete}) => {
    Modal.confirm({
      title: 'Confirm delete',
      content: `Delete ${text} ?`,
      onOk: () => {
        handleDelete()
      },
      onCancel () {}
    })
  }
  removeShirt = this.props.removeItemHandler({ path: 'shirts' })
  removeIndexItemHandler = this.props.removeIndexItemHandler({ path: 'shirts' })
  removeShirtItem = (itemIndex) => {
    const {itemsDeletedIndexes} = this.state
    this.removeIndexItemHandler(itemIndex)
    itemsDeletedIndexes.push(itemIndex)
    this.setState({itemsDeletedIndexes: _uniq(itemsDeletedIndexes)})
  }
  getShirtTypes = this.props.getItemsHandler({ path: 'shirtTypes' })
  getShirts = this.props.getItemsHandler({ path: 'shirts' })
  addShirt = this.props.addItemHandler({ path: 'shirts',
    defaultValues: defaults.shirts,
    initialValue: {
      ...initialValues.shirt,
      shirtTypeId: this.props.shirtTypeId || newShirtTypeId
    }
  })
  render () {
    const { form, event, t } = this.props
    const { getFieldDecorator, getFieldValue } = form
    let shirtTypeId = this.props.shirtTypeId || newShirtTypeId
    let shirtTypes = getFieldValue('shirtTypes') || [...(_get(event, 'shirtTypes') || []),
      ...(this.props.shirtTypeId ? [] : [{
        ...initialValues.shirtType,
        _id: shirtTypeId
      }])
    ]
    const shirtType = shirtTypes.find(s => s._id === shirtTypeId)

    if (shirtTypeId && !shirtType) {
      return Error404
    }
    let shirts = getFieldValue('shirts') || [...(_get(event, 'shirts') || []),
      ...(this.props.shirtTypeId ? [] : defaults.shirts.map(s => {
        return {
          ...s,
          shirtTypeId
        }
      }))
    ]
    const { itemsDeletedIndexes } = this.state
    if (shirts) {
      shirts = shirts.map((item, index) => {
        if (itemsDeletedIndexes.includes(index)) {
          item.__deleted = true
        }
        return item
      })
    }
    return <Form>
      <h3>{t('events.edit.shirt.type')}</h3>
      {shirtTypes.map((shirtType, shirtTypeIndex) => {
        getFieldDecorator(`shirtTypes[${shirtTypeIndex}]._id`, {
          initialValue: _get(shirtType, '_id') || mongoid()
        })
        let displayNone = shirtTypeId === shirtType._id ? '' : 'displayNone'
        displayNone = shirtType.__deleted ? 'displayNone' : displayNone
        return <RepeaterItem key={shirtTypeIndex} deleted={shirtType.__deleted} className={displayNone}>
          <Form.Item label={`${t('events.edit.shirt.typeName')} [EN]`} {...formItemLayout}>
            {getFieldDecorator(`shirtTypes[${shirtTypeIndex}].name.en`, {
              initialValue: _get(shirtType, 'name.en'),
              rules: [{ required: true, message: 'Please enter shirt type [EN]' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label={`${t('events.edit.shirt.typeName')} [${config.default_locale.toUpperCase()}]`} {...formItemLayout} >
            {getFieldDecorator(`shirtTypes[${shirtTypeIndex}].name.${config.default_locale}`, {
              initialValue: _get(shirtType, `name.${config.default_locale}`),
              rules: [{ required: true, message: `Please enter shirt type ${config.default_locale}` }]
            })(<Input />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label={`${t('events.edit.shirt.url')}`}>
            {getFieldDecorator(`shirtTypes[${shirtTypeIndex}].imageUrl`, {
              initialValue: _get(shirtType, 'imageUrl')
            })(
              <Uploader />
            )}
          </Form.Item>
          <hr/>
        </RepeaterItem>
      })}
      <h3>{t('events.edit.shirt.size')}</h3>

      {shirts.map((shirt, shirtIndex) => {
        getFieldDecorator(`shirts[${shirtIndex}]._id`, {
          initialValue: shirt._id
        })
        !getFieldValue(`temp.shirts[${shirtIndex}].limit`) &&
          getFieldDecorator(`shirts[${shirtIndex}].quantity`, {
            initialValue: null
          })
        let displayNone = shirtTypeId === shirt.shirtTypeId ? '' : 'displayNone'
        displayNone = shirt.__deleted ? 'displayNone' : displayNone
        getFieldDecorator(`shirts[${shirtIndex}].shirtTypeId`, {
          initialValue: _get(shirt, 'shirtTypeId')
        })
        shirt.__deleted && getFieldDecorator(`shirts[${shirtIndex}].__deleted`, {
          initialValue: true
        })
        return (
          <RepeaterItem key={shirtIndex} deleted={shirt.__deleted} className={displayNone}>
            <Button icon="delete" className="delete"
              onClick={() => this.confirmDelete({
                text: 'shirt',
                handleDelete: () => this.removeShirtItem(shirtIndex)})}
              style={{ position: 'absolute', right: 0, zIndex: 1 }}/>
            <Form.Item label={`${t('events.edit.shirt.sizeName')} [EN]`} {...formItemLayout}>
              {getFieldDecorator(`shirts[${shirtIndex}].name.en`, {
                initialValue: _get(shirt, 'name.en'),
                rules: [{ required: true, message: 'กรุณากรอกชื่อไซส์เสื้อเป็นภาษาอังกฤษ' }]
              })(<Input />)}
            </Form.Item>
            <Form.Item label={`${t('events.edit.shirt.sizeName')} [${config.default_locale.toUpperCase()}]`} {...formItemLayout} >
              {getFieldDecorator(`shirts[${shirtIndex}].name.${config.default_locale}`, {
                initialValue: _get(shirt, `name.${config.default_locale}`),
                rules: [{ required: true, message: 'กรุณากรอกชื่อไซส์เสื้อเป็นภาษาไทย' }]
              })(<Input />)}
            </Form.Item>
            {/* isLimit shirt quantity */}
            <Form.Item label={`${t('events.edit.shirt.soldOut')}`} {...formItemLayout} >
              {getFieldDecorator(`shirts[${shirtIndex}].isFull`, {
                initialValue: _get(shirt, 'isFull'),
                valuePropName: 'checked'
              })(<Switch />)}
            </Form.Item>
            <Form.Item label={`${t('events.edit.shirt.limit')}`} {...formItemLayout} >
              {getFieldDecorator(`temp.shirts[${shirtIndex}].limit`, {
                initialValue: _get(shirt, 'quantity') !== null,
                valuePropName: 'checked'
              })(<Switch />)}
            </Form.Item>
            {/* if isLimit */}
            {getFieldValue(`temp.shirts[${shirtIndex}].limit`) && <Form.Item label={`${t('events.edit.shirt.quantity')}`} {...formItemLayout} >
              {getFieldDecorator(`shirts[${shirtIndex}].quantity`, {
                initialValue: _get(shirt, 'quantity') == null ? 0 : _get(shirt, 'quantity')
              })(<InputNumber min={-1} />)}
            </Form.Item>}
            <i>{t('events.edit.shirt.outofstocks')}</i>
            <hr/>
          </RepeaterItem>
        )
      })}
      <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 16, offset: 8 } } } >
        <Button className="create" icon="plus-circle" onClick={this.addShirt}>{t('events.edit.shirt.sizeAdd')}</Button>
      </Form.Item>
    </Form>
  }
}

export default withTranslation() (ShirtTypeForm)
