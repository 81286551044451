import logoThairun from './assets/images/logo.png'
import logoMenu from './assets/images/logonews.png'
import logoMas from './assets/images/mas/logo.png'
import logoIndo from './assets/images/indo/logo.png'

const platforms = [{
  platform: 'thai',
  domains: ['admin.race.thai.run', 'admin.race.phuket.run', 'admin.race.thai.local'],
  logo: logoThairun,
  title: 'Race ThaiRun Admin',
  logo_menu: logoMenu,
  default_locale: 'th',
  locale_list: ['th', 'en'],
  hiddenField : true,
  hiddenParticipantFields: [],
  paymentMethods: null,
  paymentGateways: null,
  timezone: 'Asia/Bangkok'
}, {
  platform: 'maybank',
  domains: ['staging.maybankmarathon2024.com', 'maybankmarathon2024.com', 'admin.race.maybank.local', 'staging.admin.maybankmarathon2024.com'],
  logo: logoIndo,
  title: 'Race Maybank Admin',
  logo_menu: logoMenu,
  default_locale: 'id',
  locale_list: ['id', 'en'],
  hiddenField : true,
  hiddenParticipantFields: [],
  paymentMethods: null,
  paymentGateways: null,
  timezone: 'Asia/Bangkok'
}, {
  platform: 'indo',
  domains: ['indo.run', 'race.staging.indo.run', 'race.indo.run', 'admin.race.indo.local'],
  logo: logoIndo,
  title: 'Race Indo Admin',
  logo_menu: logoMenu,
  default_locale: 'id',
  locale_list: ['id', 'en'],
  hiddenField : true,
  hiddenParticipantFields: [],
  paymentMethods: null,
  paymentGateways: null,
  timezone: 'Asia/Bangkok'
}, {
  platform: 'viet',
  domains: ['race.staging.viet.run', 'race.viet.run', 'viet.run', 'admin.race.viet.local'],
  logo: logoThairun,
  title: 'Race Viet Admin',
  logo_menu: logoMenu,
  default_locale: 'vi',
  locale_list: ['vi', 'en'],
  hiddenField : true,
  hiddenParticipantFields: [],
  paymentMethods: null,
  paymentGateways: null,
  timezone: 'Asia/Bangkok'
}, {
  platform: 'mice',
  domains: ['mice.phuket.run', 'mice.thai.run', 'admin.race.mice.local'],
  logo: logoThairun,
  title: 'Race Mice Admin',
  logo_menu: logoMenu,
  default_locale: 'th',
  locale_list: ['th', 'en'],
  hiddenField : true,
  hiddenParticipantFields: [],
  paymentMethods: null,
  paymentGateways: null,
  timezone: 'Asia/Bangkok'
},{
  platform: 'mas',
  domains: ['mas.run', 'race.staging.mas.run', 'race.mas.run', 'admin.race.mas.local'],
  logo: logoMas,
  title: 'Race Mas Admin',
  logo_menu: logoMas,
  default_locale: 'ms',
  locale_list: ['ms', 'en'],
  hiddenField : false,
  hiddenParticipantFields: ['imageOtherUrl', 'consent', 'prefix'],
  paymentMethods: ['omise_card', 'omise_duitnow_obw', 'omise_touchngo', 'omise_boost'],
  paymentGateways: ['omise'],
  timezone: 'Asia/Kuala_Lumpur'
}]

export default platforms.find(p => p.domains.some(domain => window.location.origin.includes(domain)))
